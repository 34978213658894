import { performAPIAction } from './BaseService';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';
import { emailTemplateRepository, emailTypeRepository, serializeEmailTemplates } from '../kit/repository';

interface ServiceProps {
    filters: Record<string, any>;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: null | ((isSuccess: boolean, data: ServiceResult) => void);
}

export const EmailTemplateService = (props: ServiceProps) => {
    return {
        fetchAll: (filters: Record<string, any>) =>
            performAPIAction({
                ...props,
                apiCall: emailTemplateRepository.fetchAll(filters),
                errorMessageId: 'FETCH.EMAIL.TEMPLATES.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        fetch: (templateId: string) =>
            performAPIAction({
                ...props,
                apiCall: emailTemplateRepository.fetch(templateId),
                errorMessageId: 'FETCH.EMAIL.TEMPLATES.ACCESS.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        fetchTypes: () =>
            performAPIAction({
                ...props,
                apiCall: emailTypeRepository.fetchAll(),
                errorMessageId: 'EMAIL.TEMPLATES.SETUP.TYPES.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        fetchType: (typeId: string) =>
            performAPIAction({
                ...props,
                apiCall: emailTypeRepository.fetch(typeId),
                errorMessageId: 'EMAIL.TEMPLATES.SETUP.TYPES.ERROR',
                callback: null // don't execute successCallback in case of fetch
            }),

        create: (templates: any) =>
            performAPIAction({
                ...props,
                apiCall: emailTemplateRepository.create(serializeEmailTemplates(templates, true)),
                successMessageId: 'CREATE_TEMPLATE.EMAIL.TEMPLATES.CREATE.SUCCESS',
                errorMessageId: 'CREATE_TEMPLATE.EMAIL.TEMPLATES.CREATE.ERROR'
            }),

        update: (template: any) =>
            performAPIAction({
                ...props,
                apiCall: emailTemplateRepository.update(template.id, serializeEmailTemplates(template, false)),
                successMessageId: 'CREATE_TEMPLATE.EMAIL.TEMPLATES.UPDATE.SUCCESS',
                errorMessageId: 'CREATE_TEMPLATE.EMAIL.TEMPLATES.UPDATE.ERROR'
            }),

        delete: (templateId: string) =>
            performAPIAction({
                ...props,
                apiCall: emailTemplateRepository.delete(templateId),
                successMessageId: 'CREATE_TEMPLATE.EMAIL.TEMPLATES.DELETE.SUCCESS',
                errorMessageId: 'CREATE_TEMPLATE.EMAIL.TEMPLATES.DELETE.ERROR'
            }),
    };
};
